import { ChangeEventHandler, useContext, useEffect, useState } from "react";
import { BaseTable, Button, Drawer, Grid, Modal, Positions, Select, Sentiments, Sizes, Spacings, Text, TextInput, Variants } from "@sede-x/shell-ds-react-framework";
import MappingOptions from "./Options";
import MappingGroupDropdown from "./MappingGroupDropdown";
import MappingGroupDetailsView from "./MappingGroupDetailsView";
import LookUpBanner from "./LookUpBanner";
import {
  IField,
  IFieldValue,
  IFieldValueGroupMapping,
  IFieldValueGroupMappingRow,
  IGroupMapping,
  MappingGroupDefault,
  MappingGroupValues,
  System,
  ValueItem,
  DefaulValueItem
} from "../../../models/models";
import { FieldContext } from "../../../Context";
import { getColumnDefinition } from "../../../utils/getColumnDefinition";
import generateOptions from "../utils/generateOptions";
import getMappingGroups from "../../../utils/getMappingGroups";
import SelectNotFoundContent from "./SelectNotFoundContent";
import EditTableHeader from "./EditTableHeader";
import { Add } from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import generateFieldOptions from "../utils/generateFieldOptions";
import generateFieldValueOptions from "../utils/generateFieldValueOptions";
import SourceTargetBanner from "./SourceTargetBanner";
import getColumnDuplicates from "../utils/getColumnDuplicates";
import generateTableData from "../utils/generateTableData";
import getNewMappingColumnData from "../utils/getNewMappingColumnData";
import getFilteredDrawerData from "../utils/getFilteredDrawerData";
import updateNewMappingModel from "../utils/updateNewMappingModel";
import saveMappingDataSet from "../utils/saveMappingDataSet";
import { useCreateFieldValueGroups } from "../../../api/createMappingGroups";
import { useUpdateMapping } from "../../../api/updateMapping";
import { useUpdateGroupMapping } from "../../../api/updateGroupMapping";
import filterOutItems from "../utils/filterOutItems";
import { Dictionary } from "lodash";
import { useAddFieldValue } from "../../../api/addFieldValue";
import ConfirmModalHeader from "./ConfirmModalHeader";
import { useAddField } from "../../../api/addField";
import React from "react";
import { createFromGroup, createToGroup, createGroupMapping } from "../utils/mapFieldValueGroups";
import { checkFieldValueGroupExists } from "../../../api/fieldValueGroups";
import updateColumnDefinitions from "../utils/updateColumnDefinitions";

const EditNewMappingItem = ({ index, item, allFieldValues, system, showLabel, onNewMappingDataChange }: {
  index: number,
  item: ValueItem,
  allFieldValues: Dictionary<IFieldValue[]>,
  system: System,
  showLabel: boolean,
  onNewMappingDataChange: (value: string, index: number) => void
}) => {


  const [isLoading, setIsLoading] = useState(false);
  const [enteredText, setEnteredText] = useState('');
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);


  const useAddFieldValueMutation = useAddFieldValue();

  const handleAddNewMapping = (item: ValueItem, system: System) => {
    setIsLoading(true);

    const model = {
      fieldName: item.label,
      value: enteredText,
      systemId: system.id,
      systemName: system.name,
      modifiedBy: 'system'

    } as IFieldValue;

    useAddFieldValueMutation.mutate({ fieldValue: model },
      {
        onSuccess: () => {
          setIsLoading(false);

          let fieldValues = allFieldValues[item.label];
          fieldValues.push(model);
          onNewMappingDataChange(enteredText, index);

          setEnteredText('');
          setSuccessModalOpen(true);
        }, onError: () => {
          setIsLoading(false);
          setEnteredText('');
          setErrorModalOpen(true);
        }
      });
  };

  if (showLabel) {
    return (
      <>
        <div key={index}>
          <Text size="small" bold={true} style={{ fontSize: "12px" }}>
            {item.label}
          </Text>

          <Select size="medium" allowClear optionFilterProp="value" optionLabelProp="label" value={item.value}
            loading={isLoading} onSearch={setEnteredText}
            options={generateOptions(item.label, allFieldValues, system.id)}
            onChange={val => onNewMappingDataChange(val, index)}
            notFoundContent={SelectNotFoundContent({ handleAddNewMapping, valueItem: item, system })} />
        </div>


        <Modal title={<ConfirmModalHeader headerText="Error" fontColor="rgb(221, 29, 33)" />}
          open={errorModalOpen} zIndex={1000}
          padding={Spacings.Tight}
          size={Sizes.Small} centered
          closable={false}
          actions={[{
            props: { variant: Variants.Filled, sentiment: Sentiments.Negative },
            label: "OK",
            action: () => { setErrorModalOpen(false) }
          }
          ]} onClose={() => { }} >
          <p style={{
            paddingTop: "1.5rem",
            textAlign: 'center',
            fontFamily: "Shell Font",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "24px",
            letterSpacing: "0em",
            fontStyle: "normal",
            margin: "unset",
            color: "rgb(221, 29, 33)"
          }}>There was an error adding new field value!</p>
        </Modal>

        <Modal title={<ConfirmModalHeader headerText="Success" fontColor="rgb(0, 133, 87);" />} open={successModalOpen} zIndex={1000} padding={Spacings.Tight} size={Sizes.Small} centered closable={false}
          actions={[{
            props: { variant: Variants.Filled, sentiment: Sentiments.Positive },
            label: "OK",
            action: () => { setSuccessModalOpen(false) }
          }
          ]} onClose={() => { }} >
          <p style={{
            paddingTop: "1.5rem",
            textAlign: 'center',
            fontFamily: "Shell Font",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "24px",
            letterSpacing: "0em",
            fontStyle: "normal",
            margin: "unset",
            color: "rgb(0, 133, 87)"
          }}>New field value has been added successfully!</p>
        </Modal>

      </>
    );
  } else {
    return (
      <>
        <div key={index}>

          <Select size="medium" allowClear optionFilterProp="value" optionLabelProp="label" value={item.value}
            loading={isLoading} onSearch={setEnteredText}
            options={generateOptions(item.label, allFieldValues, system.id)}
            onChange={val => onNewMappingDataChange(val, index)}
            notFoundContent={SelectNotFoundContent({ handleAddNewMapping, valueItem: item, system })} />
        </div>


        <Modal title={<ConfirmModalHeader headerText="Error" fontColor="rgb(221, 29, 33)" />} open={errorModalOpen} zIndex={1000} padding={Spacings.Tight} size={Sizes.Small} centered closable={false}
          actions={[{
            props: { variant: Variants.Filled, sentiment: Sentiments.Negative },
            label: "OK",
            action: () => { setErrorModalOpen(false) }
          }
          ]} onClose={() => { }} >
          <p style={{
            paddingTop: "1.5rem",
            textAlign: 'center',
            fontFamily: "Shell Font",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "24px",
            letterSpacing: "0em",
            fontStyle: "normal",
            margin: "unset",
            color: "rgb(221, 29, 33)"
          }}>There was an error adding new field value!</p>
        </Modal>

        <Modal title={<ConfirmModalHeader headerText="Success" fontColor="rgb(0, 133, 87);" />} open={successModalOpen} zIndex={1000} padding={Spacings.Tight} size={Sizes.Small} centered closable={false}
          actions={[{
            props: { variant: Variants.Filled, sentiment: Sentiments.Positive },
            label: "OK",
            action: () => { setSuccessModalOpen(false) }
          }
          ]} onClose={() => { }} >
          <p style={{
            paddingTop: "1.5rem",
            textAlign: 'center',
            fontFamily: "Shell Font",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "24px",
            letterSpacing: "0em",
            fontStyle: "normal",
            margin: "unset",
            color: "rgb(0, 133, 87)"
          }}>New field value has been added successfully!</p>
        </Modal>

      </>
    );
  }
};

const AddFieldItem = ({ index, valueItem, allFieldValues, system, targetColDef, onLabelChange }:
  {
    index: number;
    valueItem: ValueItem;
    allFieldValues: Dictionary<IFieldValue[]>,
    system: System,
    targetColDef: any,
    onLabelChange: (value: string, index: number) => void
  }
) => {

  const [isLoading, setIsLoading] = useState(false);
  const [enteredText, setEnteredText] = useState('');
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);


  const useAddFieldMutation = useAddField();

  const handleAddNewField = (valueItem: ValueItem, system: System) => {
    setIsLoading(true);

    const model = {
      name: enteredText,
      systemName: system.name,
      dataType: 'string',
      modifiedBy: 'system'

    } as IField;

    useAddFieldMutation.mutate({ field: model },
      {
        onSuccess: () => {
          setIsLoading(false);
          allFieldValues[model.name] = [];
          onLabelChange(enteredText, index);
          setEnteredText('');
          setSuccessModalOpen(true);
        }, onError: () => {
          setIsLoading(false);
          setEnteredText('');
          setErrorModalOpen(true);
        }
      });
  };

  return (

    <>
      <Select size="medium" allowClear optionFilterProp="value" optionLabelProp="label"
        placement="bottom" loading={isLoading} onSearch={setEnteredText}
        notFoundContent={SelectNotFoundContent({ handleAddNewMapping: handleAddNewField, valueItem, system })}
        options={generateFieldOptions(allFieldValues, system.id, targetColDef ? targetColDef.map((col: any) => col.header?.toString() ?? "") : [])}
        value={valueItem.label}
        onChange={val => onLabelChange(val, index)} />


      <Modal title={<ConfirmModalHeader headerText="Error" fontColor="rgb(221, 29, 33)" />} open={errorModalOpen} zIndex={1000} padding={Spacings.Tight} size={Sizes.Small} centered closable={false}
        actions={[{
          props: { variant: Variants.Filled, sentiment: Sentiments.Negative },
          label: "OK",
          action: () => { setErrorModalOpen(false) }
        }
        ]} onClose={() => { }} >
        <p style={{
          paddingTop: "1.5rem",
          textAlign: 'center',
          fontFamily: "Shell Font",
          fontWeight: "600",
          fontSize: "20px",
          lineHeight: "24px",
          letterSpacing: "0em",
          fontStyle: "normal",
          margin: "unset",
          color: "rgb(221, 29, 33)"
        }}>There was an error adding new field value!</p>
      </Modal>

      <Modal title={<ConfirmModalHeader headerText="Success" fontColor="rgb(0, 133, 87);" />} open={successModalOpen} zIndex={1000} padding={Spacings.Tight} size={Sizes.Small} centered closable={false}
        actions={[{
          props: { variant: Variants.Filled, sentiment: Sentiments.Positive },
          label: "OK",
          action: () => { setSuccessModalOpen(false) }
        }
        ]} onClose={() => { }} >
        <p style={{
          paddingTop: "1.5rem",
          textAlign: 'center',
          fontFamily: "Shell Font",
          fontWeight: "600",
          fontSize: "20px",
          lineHeight: "24px",
          letterSpacing: "0em",
          fontStyle: "normal",
          margin: "unset",
          color: "rgb(0, 133, 87)"
        }}>New field value has been added successfully!</p>
      </Modal>
    </>
  );
};


const MappingGroupDetails = ({
  mappingGroupInfo,
  mappingGroupOptions,
  setAlertHidden,
  setInfo,
  isAdmin,
  currentUser
}: {
  mappingGroupInfo: Map<string, Map<number, IFieldValueGroupMappingRow>>;
  mappingGroupOptions: string[];
  setAlertHidden: (value: { hidden: boolean; message: string; alertType: string }) => void;
  setInfo: (value: Map<string, Map<number, IFieldValueGroupMappingRow>>) => void;
  isAdmin: boolean;
  currentUser: string | undefined;
}) => {

  const allFieldValues = useContext(FieldContext);

  const createFieldValueGroupsMutation = useCreateFieldValueGroups();
  const addMappingGroupsMutation = useUpdateMapping();
  const addNewGroupMappingMutation = useUpdateGroupMapping();

  const [selectedMappingGroup, setSelectedMappingGroup] = useState<string>(mappingGroupOptions[0]);
  const [openNewMappingDrawer, setOpenNewMappingDrawer] = useState<boolean>(false);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [drawerFilterValue, setDrawerFilterValue] = useState<string>("");

  const { sourceColDef, targetColDef } = getColumnDefinition(mappingGroupInfo, selectedMappingGroup);
  const { fromSystem, toSystem } = getMappingGroups(mappingGroupInfo, selectedMappingGroup);

  const selectedMappingGroupData = Array.from(mappingGroupInfo?.get(selectedMappingGroup)?.entries() ?? []).map((row) => row[1]);
  const columnDef = getColumnDefinition(mappingGroupInfo, selectedMappingGroup);
  const newMappingColumnData = getNewMappingColumnData(sourceColDef, targetColDef);

  const [addNewMappingItems, setAddNewMappingItems] = useState<MappingGroupValues>(newMappingColumnData);

  const filteredNewMappingData = getFilteredDrawerData(addNewMappingItems, drawerFilterValue);
  const [addFilteredNewMappingData, setAddFilteredNewMappingData] = useState<{ sourceData: Array<{ label: string, value: string }>, targetData: Array<{ label: string, value: string }> }>(filteredNewMappingData);

  useEffect(() => {
    console.log(selectedMappingGroup + " new value");
    console.log(sourceColDef[0].header + " new value");
  }, [selectedMappingGroup]);

  const handleDrawerClose = (value: boolean) => {
    setOpenNewMappingDrawer(value);
    setDrawerFilterValue("");
  };

  const handleSearchInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setDrawerFilterValue(event.target.value);
  };

  const handleSearchInputClear = () => {
    setDrawerFilterValue("");
  };


  const [modalEditTableOpen, setModalEditTableOpen] = useState(false);

  const [modalEditTableReviewMappingsOpen, setModalEditTableReviewMappingsOpen] = useState(false);

  const [editTableItems, setEditTableItems] = useState<Array<ValueItem>>([DefaulValueItem()]);

  const [areTableItemsFilled, setTableItemsFilled] = useState(false);

  const [areNewMappingItemsFilled, setNewMappingItemsFilled] = useState(false);

  const [newTargetCols, setNewTargetCols] = useState<Array<{ header: string; accessorKey: string }>>([]);

  const stickyColumns = sourceColDef.map(col => col.accessorKey as string);

  const checkIfAllTableItemsAreFilled = () => {
    var status = editTableItems.every(item => item.label && item.value);
    setTableItemsFilled(status);
  };

  const checkIfAllNewMappingItemsAreFilled = () => {
    var sourceStatus = addNewMappingItems.sourceData.every(item => item.value && item.value.length > 0);
    var targetStatus = addNewMappingItems.targetData.every(item => item.value && item.value.length > 0);

    setNewMappingItemsFilled(sourceStatus && targetStatus);
  };

  const onAddNewColumn = () => {
    setEditTableItems([...editTableItems, { label: "", value: "" }]);
    setTableItemsFilled(false);
  };

  const onLabelChange = (value: any, index: number) => {
    if (value) {
      const items = [...editTableItems];
      items[index] = { label: value, value: "" };
      setEditTableItems(items);
      checkIfAllTableItemsAreFilled();
    }
  };

  const onValueChange = (value: any, index: number) => {
    if (value) {
      const items = [...editTableItems];
      items[index].value = value;
      setEditTableItems(items);
      checkIfAllTableItemsAreFilled();
    }
  };

  const onNewMappingSourceDataChange = (value: any, index: number) => {
    if (value) {
      const { sourceData, targetData } = addNewMappingItems;
      const selectedItem = sourceData[index];
      if (selectedItem) {
        selectedItem.value = value;
      }

      const mappingGroupValues = {
        sourceData: [...sourceData],
        targetData: [...targetData],
        mappingGroup: MappingGroupDefault()
      } as MappingGroupValues;

      setAddNewMappingItems(mappingGroupValues);
      checkIfAllNewMappingItemsAreFilled();
    }
  };

  const onNewMappingTargetDataChange = (value: any, index: number) => {
    if (value) {
      const { sourceData, targetData } = addNewMappingItems;
      const selectedItem = targetData[index];
      if (selectedItem) {
        selectedItem.value = value;
      }
      const mappingGroupValues = {
        sourceData: [...sourceData],
        targetData: [...targetData],
        mappingGroup: MappingGroupDefault()
      } as MappingGroupValues;

      setAddNewMappingItems(mappingGroupValues);
      checkIfAllNewMappingItemsAreFilled();
    }
  };

  const onColumnValueChanged = (index: number, label: string, value: any) => {
    const item = tableData[index];
    if (item) {
      item[label] = value;
    }
  };


  debugger;

  const duplicatesMap = getColumnDuplicates(selectedMappingGroupData);
  const {updatedSourceColDef, updatedTargetColDef, dataSet } = generateTableData(selectedMappingGroupData, duplicatesMap, columnDef)
  const [tableData, setTableData] = useState(dataSet);


  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignContent: "space-between",
          backgroundColor: "snow",
        }}
      >
        <div style={{ marginTop: "2rem", paddingLeft: "1rem", display: "block", flexBasis: "100%", flexGrow: "1" }}>
          <Text size="medium" bold={true}>
            Mappings
          </Text>
        </div>

        <div style={{ marginTop: "1rem", paddingLeft: "1rem", alignItems: "center", display: "flex", flexDirection: "row" }}>

          {selectedMappingGroup && (
            <MappingGroupDropdown
              options={mappingGroupOptions}
              setMappingGroup={val => {
                setSelectedMappingGroup(val);
                addNewMappingItems.mappingGroup = MappingGroupDefault();
                const obj = { ...addNewMappingItems };
                setAddNewMappingItems(obj);
              }}
              value={selectedMappingGroup} />

          )}
          <LookUpBanner />
        </div>

        <div style={{ marginTop: "0rem", marginRight: "2rem", paddingTop: "1rem" }}>
          <MappingOptions isPopupVisible={isPopupVisible}
            isAdmin={isAdmin}
            handleOnNewMappingClick={() => {
              setPopupVisible(false);
              setOpenNewMappingDrawer(true);

              const { sourceColDef, targetColDef } = getColumnDefinition(mappingGroupInfo, selectedMappingGroup);
              const newMappingColumnData = getNewMappingColumnData(sourceColDef, targetColDef);
              setAddNewMappingItems(newMappingColumnData)

              const filteredNewMappingData = getFilteredDrawerData(newMappingColumnData, drawerFilterValue);
              setAddFilteredNewMappingData(filteredNewMappingData);
            }}
            handleOnEditTableClick={() => {
              setPopupVisible(false);
              setModalEditTableOpen(true);
            }} />
        </div>

        <Drawer open={openNewMappingDrawer}
          header="Add New Mapping" width="30%" sticky={true}
          onClose={() => {
            setAddNewMappingItems(newMappingColumnData);
            checkIfAllNewMappingItemsAreFilled();
            handleDrawerClose(false);
          }}
          actions={[
            {
              props: { variant: Variants.Filled, disabled: (areNewMappingItemsFilled === false), sentiment: Sentiments.Primary },
              label: "Save Mapping",
              action: () => {
                const model = updateNewMappingModel(addNewMappingItems, selectedMappingGroupData);

                const fromGroup = createFromGroup(model, currentUser);
                const toGroup = createToGroup(model, currentUser);
                const groups = [fromGroup, toGroup];

                const fieldValueMappings = saveMappingDataSet(mappingGroupInfo, allFieldValues, selectedMappingGroup, model, currentUser);

                checkFieldValueGroupExists(fieldValueMappings.source.systemId, fieldValueMappings.source.fieldValueIds)
                  .then(fieldValueGroupExists => {

                    if (!fieldValueGroupExists) {
                      createFieldValueGroupsMutation.mutate({ inserts: groups }, {
                        onSuccess: () => {
                          const groupMapping = createGroupMapping(model, currentUser);
                          addNewGroupMappingMutation.mutate({ updates: [groupMapping] }, {
                            onSuccess: () => {
                              addMappingGroupsMutation.mutate({ updates: fieldValueMappings.inserts }, {
                                onSuccess: () => {
                                  setAlertHidden({ hidden: false, message: "Mapping data saved successfully", alertType: "success" });
                                  handleDrawerClose(false);
                                  window.location.reload();
                                },
                                onError: () => {
                                  setAlertHidden({ hidden: false, message: "Error saving mapping data. Failure adding new group mapping values", alertType: "error" });
                                  handleDrawerClose(false);
                                }
                              });
                            }, onError: () => {
                              setAlertHidden({ hidden: false, message: "Error saving mapping data. Failure adding new group mappings", alertType: "error" });
                              handleDrawerClose(false);
                            }
                          });
                        }, onError: () => {
                          setAlertHidden({ hidden: false, message: "Error saving mapping data. Failure creating mapping groups", alertType: "error" });
                          handleDrawerClose(false);
                        }
                      });
                    } else {
                      setAlertHidden({ hidden: false, message: "Error saving group mapping values. New group mapping values already exist. Select different combination of values", alertType: "error" });
                      handleDrawerClose(false);
                    }

                  });
              }
            }]
          }
        >
          <>
            <div className="drawer-box" style={{ paddingBottom: "1.5rem" }}>
              <TextInput placeholder="Search" clearable value={drawerFilterValue} onChange={handleSearchInputChange} onClear={handleSearchInputClear} />
            </div>
            <div className="drawer-box" style={{ backgroundColor: "rgb(245, 245, 245)", paddingLeft: "0", }}>
              <Text bold={true} style={{ fontSize: "14px" }}>Source</Text>
            </div>

            <div style={{ marginBottom: "1rem" }}>
              <div style={{ marginTop: "1rem" }}>
                <Grid columns="50% 50%" rowGap="1rem">
                  {addFilteredNewMappingData && allFieldValues && addFilteredNewMappingData.sourceData && addFilteredNewMappingData.sourceData.map((item, index) =>
                    <EditNewMappingItem
                      index={index}
                      item={item}
                      allFieldValues={allFieldValues}
                      system={fromSystem}
                      showLabel={true}
                      onNewMappingDataChange={onNewMappingSourceDataChange} />
                  )}
                </Grid>
              </div>
            </div>

            <div className="drawer-box" style={{ backgroundColor: "rgb(245, 245, 245)", paddingLeft: "0" }}>
              <Text bold={true} style={{ fontSize: "14px" }}>Target</Text>
            </div>

            <div>
              <div style={{ marginTop: "1rem" }}>

                <Grid columns="50% 50%" rowGap="1rem">
                  {addFilteredNewMappingData && allFieldValues && addFilteredNewMappingData.targetData && addFilteredNewMappingData.targetData.map((item, index) =>
                    <EditNewMappingItem
                      index={index}
                      item={item}
                      allFieldValues={allFieldValues}
                      system={toSystem}
                      showLabel={true}
                      onNewMappingDataChange={onNewMappingTargetDataChange} />
                  )}
                </Grid>

              </div>
            </div>
          </>
        </Drawer>


        <Modal open={modalEditTableOpen}
          padding={Spacings.Generous} size={Sizes.Large}
          centered={false} closable={true}
          width={"50%"}
          height={"850px"}
          contentScrollable={true}
          headerAreaComponent={EditTableHeader(0)}
          onClose={() => {
            const updatedTableData = filterOutItems(tableData, editTableItems.map(item => item.label));
            setTableData(updatedTableData);
            setEditTableItems([{ label: "", value: "" }]);
            setNewTargetCols([]);
            setModalEditTableOpen(false)
          }}
          actions={[
            {
              props: { variant: Variants.Filled, disabled: (areTableItemsFilled === false), sentiment: Sentiments.Primary },
              label: "Next",
              action: () => {
                let cols = new Array<{ header: string, accessorKey: string }>
                let firstRun = true;

                debugger;
                for (let i = 0; i < dataSet.length; i++) {
                  const item = dataSet[i];
                  for (const editItem of editTableItems) {

                    if (!item[editItem.label]) {

                      if (firstRun) {
                        const newTargetColDefs = {
                          header: editItem.label,
                          accessorKey: editItem.label,
                          width: 250,
                          cell: (_: any) => <Select
                            size={Sizes.Small}
                            allowClear
                            defaultValue={editItem.value}
                            placeholder=""
                            optionFilterProp="value" optionLabelProp="label"
                            onChange={(value) => onColumnValueChanged(i, editItem.label, value)}
                            options={generateFieldValueOptions(editItem, allFieldValues, toSystem.id)}
                          />
                        };
                        cols.push(newTargetColDefs);
                      }
                      item[editItem.label] = editItem.value;
                    }
                  }
                  firstRun = false;
                }

                setTableData([...dataSet]);
                setNewTargetCols([...cols]);
                setModalEditTableReviewMappingsOpen(true);
                setModalEditTableOpen(false);
              }
            },
          ]}
        >
          <div style={{ textAlign: "center" }}>
            <Button style={{ width: "80%" }} size={Sizes.Medium}
              variant={Variants.Outlined} icon={<Add />}
              iconPosition={Positions.Right}
              onClick={onAddNewColumn}>
              Add New Target Columns
            </Button>
          </div>

          <div id="elements" style={{ width: "80%", margin: "0 auto", marginTop: "1.5rem", height: "450px" }}>
            <Grid columns="auto 80px auto">
              <Grid.Cell>
                <Text size="medium" bold={true} style={{ fontSize: "16px" }}>
                  Target
                </Text>
              </Grid.Cell>

              <Grid.Cell></Grid.Cell>

              <Grid.Cell>
                <Text size="medium" bold={true} style={{ fontSize: "16px" }}>
                  Value
                </Text>
              </Grid.Cell>
              {editTableItems && allFieldValues && editTableItems.map((item, index) => {
                return (
                  <>
                    <Grid.Cell>
                      <AddFieldItem index={index} valueItem={item}
                        allFieldValues={allFieldValues} system={toSystem}
                        targetColDef={targetColDef}
                        onLabelChange={onLabelChange} />
                    </Grid.Cell>
                    <Grid.Cell><div style={{ textAlign: "center" }}>=</div></Grid.Cell>
                    <Grid.Cell>
                      <EditNewMappingItem index={index} item={item}
                        allFieldValues={allFieldValues} system={toSystem}
                        showLabel={false}
                        onNewMappingDataChange={onValueChange} />
                    </Grid.Cell>
                  </>
                )
              })}
            </Grid>
            <div style={{ marginBottom: "4em" }}>&nbsp;</div>
          </div>
        </Modal>


        <Modal open={modalEditTableReviewMappingsOpen}
          padding={Spacings.Generous} size={Sizes.Large}
          centered={false} closable={true}
          width={"50%"}
          contentScrollable={true}
          headerAreaComponent={EditTableHeader(1)}
          onClose={() => {
            const updatedTableData = filterOutItems(tableData, editTableItems.map(item => item.label));
            setTableData(updatedTableData);
            setEditTableItems([{ label: "", value: "" }]);
            setNewTargetCols([]);
            setModalEditTableReviewMappingsOpen(false);
          }}
          actions={[
            {
              props: { variant: Variants.Outlined, sentiment: Sentiments.Primary },
              label: "Back",
              action: () => {
                const updatedTableData = filterOutItems(tableData, editTableItems.map(item => item.label));
                setTableData(updatedTableData);
                setNewTargetCols([]);
                setModalEditTableReviewMappingsOpen(false);
                setModalEditTableOpen(true);
              }
            },
            {
              props: { variant: Variants.Filled, sentiment: Sentiments.Primary },
              label: "Save Mapping Table",
              action: () => {

                const updates = new Array<IFieldValueGroupMapping>();
                for (const tItem of editTableItems) {
                  const fieldName = tItem.label;
                  for (const item of selectedMappingGroupData) {
                    const fieldValues = item.fromGroupMappings.map(g => g.fieldValue);

                    for (const tbItem of tableData) {
                      const values = Object.keys(tbItem).map(key => tbItem[key]);
                      if (fieldValues.every(f => values.includes(f))) {

                        const mapping = {
                          fieldName: fieldName,
                          fieldValue: tbItem[fieldName],
                          systemName: toSystem.name,
                          groupName: item.toGroupMappings[0].groupName,
                          modifiedBy: "system"
                        } as IFieldValueGroupMapping;
                        updates.push(mapping);
                        break;
                      }
                    }

                  }
                }

                if (updates) {
                  addMappingGroupsMutation.mutate({ updates: updates }, {
                    onSuccess: () => {
                      setAlertHidden({ hidden: false, message: "Mapping data saved successfully", alertType: "success" });
                      setModalEditTableReviewMappingsOpen(false);
                      window.location.reload();
                    },
                    onError: () => {
                      setAlertHidden({ hidden: false, message: "Error saving mapping data", alertType: "error" });
                      setModalEditTableReviewMappingsOpen(false);
                    }
                  });
                }
              }
            }
          ]}
        >

          <Grid columns="50% 50%">
            <Grid.Cell>
              <Text size="medium" bold={true} style={{ fontSize: "16px" }}>
                {selectedMappingGroup}
              </Text>
            </Grid.Cell>
            <Grid.Cell>
              <div style={{ textAlign: "right", paddingRight: "1em" }}>
                <SourceTargetBanner />
              </div>
            </Grid.Cell>
          </Grid>
          <div style={{ paddingTop: "1em" }}>
            <BaseTable
              columns={[...updatedSourceColDef, ...updatedTargetColDef, ...newTargetCols]}
              data={dataSet}
              maxHeight={550}
              maxWidth={window.innerWidth - 20}
              stickyColumns={stickyColumns}
              width="100%"
              stickyFooter
              stickyHeader
            />
          </div>

        </Modal>

      </div>

      <div>
        {selectedMappingGroup && (
          <MappingGroupDetailsView
            selectedMappingGroup={selectedMappingGroup}
            mappingGroupInfo={mappingGroupInfo}
            setAlertHidden={setAlertHidden}
            setInfo={setInfo}
            isAdmin={isAdmin}
          />
        )}

      </div>
    </>
  );
};

export default MappingGroupDetails;
