import { IFieldValueGroup, IGroupMapping, MappingGroupValues } from "../../../models/models";

const createFromGroup = (model: MappingGroupValues, currentUser: string | undefined): IFieldValueGroup => {
    return {
        id: 0,
        name: model.mappingGroup.fromGroupName,
        systemId: model.mappingGroup.fromSystem.id,
        systemName: model.mappingGroup.fromSystem.name,
        modifiedBy: currentUser ?? "system"
    } as IFieldValueGroup
};

const createToGroup = (model: MappingGroupValues, currentUser: string | undefined): IFieldValueGroup => {
    return {
        id: 0,
        name: model.mappingGroup.toGroupName,
        systemId: model.mappingGroup.toSystem.id,
        systemName: model.mappingGroup.toSystem.name,
        modifiedBy: currentUser ?? "system"
    } as IFieldValueGroup
};

const createGroupMapping = (model: MappingGroupValues, currentUser: string | undefined): IGroupMapping => {
    return {
        fromGroupName: model.mappingGroup.fromGroupName,
        fromSystemId: model.mappingGroup.fromSystem.id,
        fromSystemName: model.mappingGroup.fromSystem.name,
        toGroupName: model.mappingGroup.toGroupName,
        toSystemId: model.mappingGroup.toSystem.id,
        toSystemName: model.mappingGroup.toSystem.name,
        modifiedBy: currentUser ?? "system"
    } as IGroupMapping
};


export { createFromGroup, createToGroup, createGroupMapping };