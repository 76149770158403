import parseJwt from "./parseJwt";

export type UserProfile = {
    initials: string;
    firstName: string;
    lastName: string;
    email: string;
    fullName: string;
};

const readUserProfile = (auth: any) => {
    if (!auth.userData) {
        return null;
    }
    
    const accessToken = parseJwt(auth.userData.access_token);
    if (!accessToken) {
        return null;
    }

    const userProfile: UserProfile = {
        initials: (accessToken.givenName.charAt(0) + accessToken.sn.charAt(0)).toUpperCase(),
        firstName: accessToken.givenName,
        lastName: accessToken.sn,
        email: accessToken.email,
        fullName: accessToken.givenName + " " + accessToken.sn
    };
    
    return userProfile;
};

export default readUserProfile;