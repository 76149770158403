import { AccessorKeyColumnDef } from "@tanstack/react-table";
import { IFieldValueGroupMappingRow } from "../../../models/models";

const generateTableData = (
    selectedMappingGroupData: IFieldValueGroupMappingRow[],
    duplicatesMap: Map<string, string[]>,
    columnDef: { sourceColDef: AccessorKeyColumnDef<any>[], targetColDef: AccessorKeyColumnDef<any>[] }) => {

    const { sourceColDef, targetColDef } = columnDef;

    const dataSet = selectedMappingGroupData.map((row, idx) => {

        let dataItem = {} as Record<string, any>;

        row.fromGroupMappings.forEach((m, index) => {
            let fieldName = m.fieldName;

            let dupes = duplicatesMap.get(m.fieldName);
            const colDef = sourceColDef.find((col) => col.header === m.fieldName);
            if (idx === 0 && dupes && dupes.length > 0 && colDef) {
                fieldName = m.fieldName + dupes.pop();
                colDef['accessorKey'] = fieldName;
                colDef['header'] = m.fieldName;
            }
            else {
                if (colDef && colDef['accessorKey']) {
                    fieldName = colDef['accessorKey'] as string;
                }
            }

            dataItem[fieldName] = m.fieldValue;
        });

        row.toGroupMappings.forEach((m, index) => {
            let fieldName = m.fieldName;

            let dupes = duplicatesMap.get(m.fieldName);

            if (idx === 0 && dupes && dupes.length > 0) {
                fieldName = fieldName + dupes.pop();
                targetColDef[index].accessorKey = fieldName;
                targetColDef[index].header = m.fieldName;
            }
            else {
                const colDef = targetColDef.find((col) => col.header === m.fieldName);
                if (colDef && colDef['accessorKey']) {
                    fieldName = colDef['accessorKey'] as string;
                }
            }

            dataItem[fieldName] = m.fieldValue;
        });

        return dataItem;
    });

    let updatedSourceColDef = sourceColDef;
    let updatedTargetColDef = targetColDef;
    return { updatedSourceColDef, updatedTargetColDef, dataSet };
};

export default generateTableData;